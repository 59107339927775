import React, { Component } from 'react';



class Redirect extends Component {
	constructor(props) {
		super(props);
		
		
		this.props.history.push('/');
	}
	
	
	render() {

   return 0;
	}
	
}
export default (Redirect);
