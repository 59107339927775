/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import {
  stripslashes,
  addressFormat,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import { appId, deliveryId, CountryTxt, apiUrlNotify } from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/tick.png";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import cartMpImg from "../../common/images/cart-map.png";
import noimage from "../../common/images/no-img-product.png";
import { GET_ORDER_DETAIL, DESTROY_CART_DETAIL } from "../../actions";

var Parser = require("html-react-parser");

class Thankyou extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");
    if (orderId !== "" && chkOrderid === orderId) {
      this.props.getOrderDetail(orderId);
      /*this.sendNotification();*/
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    /*console.log('DidMount');*/
    this.props.destroyCartDetail();
    this.deleteOrderCookie();
  }

  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];
      return cartItems.map((item, index) => (
        <div key={index}>
          <div className="cart_row checkout-product-details">
            <div className="cart-lt">
              <div className="col-sm-cls cart_left">
                <div className="cart_img">
                  {item.item_image !== "" ? (
                    <img src={item.item_image} alt="" />
                  ) : (
                    <img src={noimage} alt="" />
                  )}
                </div>
                <div className="cart_info text-left">
                  <h4>
                    {stripslashes(item.item_name)} X {item.item_qty}
                  </h4>

                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}
                  {Object.keys(item.voucher_details).length > 0 &&
                    item.voucher_details.voucher_for === "gift" && (
                      <div className="cart_extrainfo">
                        <p>
                          <b>Gift Details</b>
                        </p>
                        {item.voucher_details.voucher_gift_name !== "" &&
                          item.voucher_details.voucher_gift_name !== null && (
                            <p>
                              Name: {item.voucher_details.voucher_gift_name}
                            </p>
                          )}
                        {item.voucher_details.order_item_voucher_mobile !==
                          "" &&
                          item.voucher_details.order_item_voucher_mobile !==
                            null && (
                            <p>
                              Mobile:{" "}
                              {item.voucher_details.order_item_voucher_mobile}
                            </p>
                          )}
                        {item.voucher_details.order_item_voucher_email !== "" &&
                          item.voucher_details.order_item_voucher_email !==
                            null && (
                            <p>
                              Email:{" "}
                              {item.voucher_details.order_item_voucher_email}
                            </p>
                          )}
                        {item.voucher_details.order_item_voucher_message !==
                          "" &&
                          item.voucher_details.order_item_voucher_message !==
                            null && (
                            <p>
                              Message:{" "}
                              {item.voucher_details.order_item_voucher_message}
                            </p>
                          )}
                      </div>
                    )}
                </div>
              </div>
              {parseFloat(item.item_total_amount) > 0 && (
                <div className="col-sm-cls cart_right text-right">
                  <div className="cart_price">
                    <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
                  </div>
                </div>
              )}
            </div>
            <div className="cart-rt">
              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
            </div>
          </div>
        </div>
      ));
    } else {
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
        var custom_text_combo = "";
        if (combos[r]["menu_custom_text"] !== "") {
          custom_text_combo =
            "<i class='combo-in-combo-remarks'>" +
            combos[r]["menu_custom_text"] +
            "</i>";
        }
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          html +=
            "<p>" +
            comboQty +
            " X " +
            comboPro +
            newPrice +
            "" +
            comboVal +
            "" +
            custom_text_combo +
            " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);
      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_inner_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_inner_product_name"];
          var comboQty = combos[r]["menu_inner_product_qty"];
          var comboPrice = combos[r]["menu_inner_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="tnk-you">
              <div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} alt="" />
                <h2>Thank You</h2>
                <p>Your order has been placed successfully</p>
              </div>

              {/* order-detail-maindiv - start */}
              <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>YOUR ORDER DETAILS</h2>
                  <div className="tnk-order">
                    <h3>Order No - {orderDetail.order_local_no}</h3>
                    <p>
                      Order placed at :{" "}
                      {Moment(orderDetail.order_created_on).format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                      <br></br>Pay by : {orderDetail.order_method_name}
                      {orderDetail.order_method_name ===
                        "Credit card/Debit card(Manual)" && (
                        <>
                          <br />
                          <br />
                          Please take note that the outlet will be contacting
                          you to process your credit/debit payment manually. The
                          payment for this order is not complete.
                        </>
                      )}
                    </p>
                  </div>
                </div>

                <div className="tnk-delivery">
                  {orderDetail.order_availability_id === deliveryId ? (
                    <div className="delivery-cart-div">
                      <div className="cart-direction">
                        <img className="cart-direction-left" src={scootyImg} />
                        <img className="cart-direction-right" src={cartMpImg} />
                      </div>
                      <div className="cart_row tnkorder-first">
                        <div className="order-hlhs text-left">
                          <h5>Order Handling By</h5>
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                          <p>
                            {addressFormat(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2,
                              orderDetail.outlet_address_line1,
                              orderDetail.outlet_address_line2,
                              orderDetail.outlet_postal_code
                            )}
                          </p>
                        </div>
                        <div className="order-hrhs text-right">
                          <h5>Delivery Location</h5>
                          <p>
                            {addressFormat(
                              orderDetail.order_customer_unit_no1,
                              orderDetail.order_customer_unit_no2,
                              orderDetail.order_customer_address_line1,
                              orderDetail.order_customer_address_line2,
                              orderDetail.order_customer_postal_code
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="pickup-cart-div">
                      <div className="cart-direction"></div>
                      <div className="cart_row cart-header-first">
                        <div className="pickup-thankfull text-center">
                          <h4>Pickup Location</h4>
                          <p>{stripslashes(orderDetail.outlet_name)}</p>
                          <p>
                            {addressFormat(
                              orderDetail.outlet_unit_number1,
                              orderDetail.outlet_unit_number2,
                              orderDetail.outlet_address_line1,
                              orderDetail.outlet_address_line2,
                              orderDetail.outlet_postal_code
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <br />

                  <div className="delivery_total delivery_total_number delivery_datetime_div">
                    <div className="delivery_total_left">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Date
                      </h2>
                      <h4 className="checkoutDate">
                        {Moment(orderDetail.order_date).format("DD-MM-YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>
                        {orderDetail.order_availability_id === deliveryId
                          ? "Delivery"
                          : "Pickup"}{" "}
                        time
                      </h2>
                      <h4 className="checkoutTime">
                        {Moment(orderDetail.order_date).format("hh:mm A")}
                      </h4>
                    </div>
                  </div>

                  <div className="orderitem_body_div">
                    <div className="overall-parent">
                      <div className="order-details-with-clear">
                        <h5>Your Items</h5>
                      </div>
                      {this.loadItems(orderDetail)}
                      {orderDetail.order_remarks !== "" && (
                        <div className="remark_notesec text-left">
                          <h4>Remarks</h4>
                          <p>{orderDetail.order_remarks}</p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="cart_footer tnk_cart_footer">
                    <div className="cart_row">
                      <p className="text-uppercase">SUBTOTAL</p>

                      <span>${orderDetail.order_sub_total}</span>
                    </div>

                    {parseFloat(orderDetail.order_delivery_charge) > 0 && (
                      <div className="cart_row">
                        <p className="text-uppercase">Delivery Charge</p>
                        <span>
                          $
                          {parseFloat(
                            orderDetail.order_delivery_charge
                          ).toFixed(2)}
                        </span>
                      </div>
                    )}

                    {parseFloat(orderDetail.order_additional_delivery) > 0 && (
                      <div className="cart_row">
                        <p className="text-uppercase">
                          Additional Delivery Charge
                        </p>
                        <span>
                          $
                          {parseFloat(
                            orderDetail.order_additional_delivery
                          ).toFixed(2)}
                        </span>
                      </div>
                    )}

                    {orderDetail.order_discount_applied === "Yes" && (
                      <div className="cart_row">
                        <p className="text-uppercase">
                          {orderDetail.order_discount_type === "redeem"
                            ? "Discount (-)"
                            : "Promocode (" +
                              orderDetail.order_promocode_name +
                              ")"}
                        </p>
                        {parseFloat(orderDetail.order_discount_amount) > 0 && (
                          <span>
                            $
                            {parseFloat(
                              orderDetail.order_discount_amount
                            ).toFixed(2)}
                          </span>
                        )}
                      </div>
                    )}

                    {parseFloat(orderDetail.order_voucher_discount_amount) >
                      0 && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-sm-cls text-left">
                            <p className="text-uppercase">
                              Voucher Discount(-)
                            </p>
                          </div>
                          <div className="col-sm-cls text-right">
                            <span>
                              {showPrices(
                                parseFloat(
                                  orderDetail.order_voucher_discount_amount
                                ).toFixed(2)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {parseFloat(orderDetail.order_tax_calculate_amount) > 0 &&
                      parseFloat(orderDetail.order_tax_charge_inclusive) ===
                        0 && (
                        <div className="cart_row gst-row">
                          <p className="text-uppercase">
                            GST({parseFloat(orderDetail.order_tax_charge)}%)
                          </p>
                          <span>
                            $
                            {parseFloat(
                              orderDetail.order_tax_calculate_amount
                            ).toFixed(2)}
                          </span>
                        </div>
                      )}
                    <div className="grand-total-with-gst">
                      <div className="cart_row grant-total-cls">
                        <p className="text-uppercase">Total</p>

                        <span>
                          <sup>$</sup>
                          {parseFloat(orderDetail.order_total_amount).toFixed(
                            2
                          )}
                        </span>
                      </div>
                      {parseFloat(orderDetail.order_tax_charge_inclusive) > 0 &&
                        parseFloat(
                          orderDetail.order_tax_calculate_amount_inclusive
                        ) > 0 && (
                          <p className="inclisive-gst">
                            Inclusive GST (
                            {orderDetail.order_tax_charge_inclusive}%): $
                            {parseFloat(
                              orderDetail.order_tax_calculate_amount_inclusive
                            ).toFixed(2)}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="tnk-chk-order">
                  <Link to={"/myorders"} className="button">
                    Check Order Status
                  </Link>
                </div>
              </div>
              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
