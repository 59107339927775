/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Slider from "react-slick";
import { GET_MENU_NAVIGATION } from "../../actions";
var settingsGallery = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  arrow: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 780,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class MenuNavigation extends Component {
  constructor(props) {
    super(props);
    var selectedNav = "";
    if (
      this.props.match.params.slugType !== "" &&
      typeof this.props.match.params.slugType !== undefined &&
      typeof this.props.match.params.slugType !== "undefined"
    ) {
      selectedNav = this.props.match.params.slugType;
    }
    this.state = {
      navigateMenu: [],
      selectedNav: selectedNav,
    };
    this.props.getMenuNavigationList();
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var navigateCommon = this.props.navigateCommon;
    var selectedNav = "";
    if (
      this.props.productState !== "" &&
      typeof this.props.productState !== undefined &&
      typeof this.props.productState !== "undefined"
    ) {
      selectedNav = this.props.productState.selectedNavigation;
    } else {
      selectedNav = this.state.selectedNav;
    }
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type === "main") {
          return (
            <li
              key={index + 1}
              className={selectedNav === loaddata.pro_cate_slug ? "active" : ""}
            >
              <Link
                href={"/products/category/" + loaddata.pro_cate_slug}
                title={loaddata.menu_custom_title}
              >
                {loaddata.pro_cate_default_image !== "" &&
                  loaddata.pro_cate_default_image !== null && (
                    <img
                      src={
                        navigateCommon.category_image_url +
                        "/" +
                        loaddata.pro_cate_default_image
                      }
                      alt={stripslashes(loaddata.menu_custom_title)}
                    />
                  )}
                <span>{stripslashes(loaddata.menu_custom_title)}</span>
              </Link>
              <ul>
                {this.loadSubmenu(
                  loaddata.pro_cate_slug,
                  loaddata.subcat_list_arr.sub_result_set
                )}
              </ul>
            </li>
          );
        } else if (loaddata.menu_type === "sub") {
          return (
            <li key={index + 1}>
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </li>
          );
        }
      });

      return mainMenu;
    }
  }
  loadSubmenu(pro_cate_slug, sbumenu) {
    if (sbumenu.length > 0) {
      return sbumenu.map((menu, index) => {
        return (
          <li key={index}>
            <a
              href={"/products/" + pro_cate_slug + "/" + menu.pro_subcate_slug}
            >
              {menu.pro_subcate_name}
            </a>
          </li>
        );
      });
    }
  }
  navigateMobileMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    var navigateCommon = this.props.navigateCommon;
    var selectedNav = "";
    if (
      this.props.productState !== "" &&
      typeof this.props.productState !== undefined &&
      typeof this.props.productState !== "undefined"
    ) {
      selectedNav = this.props.productState.selectedNavigation;
    } else {
      selectedNav = this.state.selectedNav;
    }
    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
        if (loaddata.menu_type === "main") {
          return (
            <div
              key={index + 1}
              className={
                selectedNav === loaddata.pro_cate_slug
                  ? "mobile-menu-parent active"
                  : "mobile-menu-parent"
              }
            >
              <a
                className="menu-title-link"
                href={"/products/category/" + loaddata.pro_cate_slug}
                title={loaddata.menu_custom_title}
              >
                {loaddata.pro_cate_default_image !== "" &&
                  loaddata.pro_cate_default_image !== null && (
                    <img
                      src={
                        navigateCommon.category_image_url +
                        "/" +
                        loaddata.pro_cate_default_image
                      }
                      alt={stripslashes(loaddata.menu_custom_title)}
                    />
                  )}
                <span>{stripslashes(loaddata.menu_custom_title)}</span>
              </a>
            </div>
          );
        } else if (loaddata.menu_type === "sub") {
          return (
            <div key={index + 1}>
              <Link
                to={"/products/subcategory/" + loaddata.pro_subcate_slug}
                className="menu-title-link"
                title={loaddata.menu_custom_title}
              >
                {stripslashes(loaddata.menu_custom_title.toLowerCase())}
              </Link>
            </div>
          );
        }
      });

      return mainMenu;
    }
  }

  render() {
    return (
      <>
        <section className="catgegory-navigation mobile-catgegory-nav">
          <div className="container">
            <div className="ca-nav-inner">
              {/*  <Slider {...settingsGallery}>
                {this.navigateMobileMenuList()}
              </Slider> */}
            </div>
          </div>
        </section>
        <section className="catgegory-navigation desktop-catgegory-nav">
          <div className="container">
            <div className="ca-nav-inner">
              <ul>{this.navigateMenuList()}</ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = [];
  var navigateRst = [];
  var navigateCmn = [];
  if (Object.keys(state.product).length > 0) {
    tempArr = !("menuNavigation" in state.product[0])
      ? []
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(MenuNavigation);
